// ----- Main imports ----- //
import { useState } from "react";
// ----- End of Main imports ----- //

// ----- import images -----
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("./assets/images", false, /\.(png|jpe?g|svg)$/)
);
// ----- End of import images -----

// ----- Loading component ----- //
const Loading = ({ calcWidth }) => {
  return (
    <aside>
      <div className="loading-bar">
        <label htmlFor="images-loaded">Loading all your images</label>
        <progress id="images-loaded" max="100" value={calcWidth}></progress>
      </div>
    </aside>
  );
};
// ----- End of Loading component ----- //

// ----- App ----- //
const App = () => {
  // hooks
  const [currentImage, setCurrentImage] = useState(0);
  const [numLoaded, setNumLoaded] = useState(0);
  // end of hooks

  // functions

  const handleClick = () => {
    setCurrentImage((currentImage) => {
      // if (currentImage < images.length - 1) {
      //   return currentImage + 1;
      // } else {
      //   return 0;
      // }
      return currentImage < images.length - 1 ? currentImage + 1 : 0;
    });
  };

  const handelImageLoad = () => {
    setNumLoaded(
      (numLoaded) => numLoaded + 1 // remember that you have to structure any state changes as callbacks if you are using previous value of state to calculate the new one
    );
  };

  // end of functions

  return (

    <section>
      <header>
        <h1>Zeo</h1>
        <h2>
          Photography project by <br /> Neo
        </h2>
      </header>
      <figure>
        {numLoaded < images.length ? (
          <Loading calcWidth={(numLoaded / images.length) * 100} />
        ) : null}
        <figcaption>

          {currentImage + 1}/{images.length}
        </figcaption>
        {images.map((imageURL, index) => (
          <img
            src={imageURL}
            alt=""
            key={imageURL}
            onClick={handleClick}
            onLoad={handelImageLoad}
            // style={{
            //   opacity:currentImage === index?1:0
            // }}
              className={currentImage===index?"display":"hide"} // this approach is more good
          />
        ))}
      </figure>
    </section>
  );
};
// ----- End of App -----

export default App;
